<template>
    <section class="h-100">
        <div class="row mx-0 px-3 pl-5 f-600 border-bottom text-general py-2 justify-content-between align-items-center">
            <p>Categorías</p>
        </div>
        <div class="p-3 f-15 h-90">
            <template v-if="!showSubcategorias">
                <div class="row mx-0 my-3 px-3 align-items-center text-general">
                    <div class="col-auto px-0">
                        <p>Crea las categorías que agruparán los productos</p>
                    </div>
                    <el-tooltip class="item" effect="light" content="Crear categoria" placement="bottom">
                        <div class="btn-red text-white cr-pointer ml-auto mr-3" style="width:120px" @click="crearCategoria(null)">
                            Añadir
                        </div>
                    </el-tooltip>
                    <div class="btn-outline cr-pointer" style="width:120px" @click="orderCategories">
                        Ordenar
                    </div>
                </div>
                <div class="overflow-auto custom-scroll" style="height:calc(100vh - 231px);">
                    <draggable
                    :list="categorias"
                    class="row mx-0 my-3 px-3"
                    tag="div"
                    ghost-class="ghost"
                    @change="checkMoveCategory"
                    @start="dragging = true"
                    @end="dragging = false"
                    >
                        <div v-for="(cat, idx) in categorias" :key="idx" class="col-auto mb-3">
                            <div class="card-categoria border br-12 position-relative" style="width:160px;height:205px;">
                                <img v-if="cat.banner_firmado" class="obj-cover border br-t-10 cr-pointer" width="160" height="158" :src="cat.banner_firmado" alt="" @click="irDetalleSubcategorias(cat)" />
                                <img v-else src="/img/no-imagen/list.svg" class="obj-cover br-t-10 cr-pointer" height="150" width="100%" @click="irDetalleSubcategorias(cat)" />
                                <p class="f-600 nombre-categoria px-2 f-17 py-2 text-left text-general">
                                    {{ cat.nombre }}
                                </p>
                                <div v-if="cat.adultos == 1" class="position-absolute bg-white rounded-circle d-middle-center" style="top:6px;right:6px;width:25px;height:25px">
                                    <el-tooltip content="Categoría para mayores de edad" placement="bottom" effect="light">
                                        <i class="icon-alert-triangle text-general f-11" style="float:rigth" />
                                    </el-tooltip>
                                </div>
                                <div v-if="cat.descripcion" class="position-absolute bg-white rounded-circle d-middle-center" style="top:125px;right:6px;width:18px;height:18px">
                                    <el-tooltip :content="cat.descripcion" placement="bottom" effect="light" popper-class="tooltip-size">
                                        <i class="icon-help-circle-outline text-general f-25" style="float:rigth" />
                                    </el-tooltip>
                                </div>
                            </div>
                            <div class="row mx-0 align-items-center">
                                <div class="col-auto px-0">
                                    <el-tooltip class="item" effect="light" content="Editar" placement="bottom">
                                        <div class="d-middle-center btn-edit cr-pointer br-10" style="width:44px;height:44px;" @click="crearCategoria(cat)">
                                            <i class="icon-pencil-outline f-18" />
                                        </div>
                                    </el-tooltip>
                                </div>
                                <div class="col-auto px-0">
                                    <el-tooltip class="item" effect="light" content="Eliminar" placement="bottom">
                                        <div class="d-middle-center cr-pointer br-10" style="width:44px;height:44px;" @click="eliminar(cat)">
                                            <i class="icon-trash-empty f-20 hover-inverse" />
                                        </div>
                                    </el-tooltip>
                                </div>
                                <div class="col-auto px-0 ml-auto">
                                    <el-tooltip class="item" effect="light" placement="bottom">
                                        <div slot="content" class="text-center" style="max-width:135px;">
                                            Productos en esta categoria
                                        </div>
                                        <div class="br-10 px-2 f-17 mb-auto bg-light-f5 text-general2 tres-puntos" style="max-width:75px;">
                                            <span>{{ cat.productos }}</span>
                                        </div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                    </draggable>
                </div>
            </template>
            <subCategorias v-if="showSubcategorias" :categoria="detalleCategoria" @emitsBotones="accionesDesdeSubCategoria" />
            <sinDatos v-if="!categorias.length" icon="list" mensaje="No se ha creado ninguna categoría" />
        </div>
        <modalCrearCategoria ref="modalCrearCategoria" @actualizar="getDatos" />
        <modal ref="modalEliminar" no-aceptar titulo="Eliminar categoría de producto" icon="trash" adicional="Eliminar" @adicional="deleteCategoria"> 
            <ValidationObserver ref="form2">
                <div class="row mx-0 justify-center text-muted2 f-15 px-4 " :class="categoria_eliminar.productos>0 ? 'mb-5' : ''">
                    <div class="col-12 px-0 mb-2 text-center">
                        <p class="my-2 text-general">Esta categoría contiene <b class="text-general2"> {{ categoria_eliminar.productos }} </b>  productos <span v-if="categoria_eliminar.productos>0">y no puede eliminarse.</span></p>
                    </div>
                    <div v-if="categoria_eliminar.productos>0" class="col-12 px-0 text-center">
                        <p class="my-2 text-general">Para eliminarla debe trasladar los productos a otra categoría.</p>
                    </div>
                    <template v-if="categoria_eliminar.productos>0">
                        <ValidationProvider v-slot="{ errors }" rules="required" name="categoría" tag="div" class="col-12 px-0 mt-2">
                            <p class="text-general pl-3 f-14">
                                Categoría
                            </p>
                            <el-select v-model="categoria" placeholder="Categoría" class="w-100">
                                <el-option
                                v-for="item in temporalCats"
                                :key="item.id"
                                :label="`${item.nombre}`"
                                :value="item.id"
                                />
                            </el-select>
                            <span class="text-danger f-11">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </template>
                    <div class="col-8 text-center">
                        <p class="m-3 text-general">¿Desea aceptar la acción?</p>
                    </div>
                </div>
            </ValidationObserver>
        </modal>
        <modalOrderCategories ref="modalOrderCategories" @update="getDatos" />
    </section>
</template>

<script>
import Categorias from "~/services/configurar/productos";

export default {
    components: {
        modalCrearCategoria: () => import('./modalCrearCategoria'),
        modalOrderCategories: () => import('./modalOrderCategories'),
        subCategorias: () => import('./subCategorias'),
    },
    data(){
        return {
            categorias: [],
            temporalCats:[],
            id_categoria: null,
            categoria:null,
            categoria_eliminar:{},

            showSubcategorias: false,
            detalleCategoria: {},
        }
    },
    mounted(){
        this.getDatos()
    },
    methods: {
        async getDatos(){
            try {
                const { data } = await Categorias.getDatosCategorias(0,1)
                this.categorias = data.categorias

                if(!_.isEmpty(this.detalleCategoria)){
                    this.actualizarDetalleCategoria();
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        eliminar(categoria){
            this.categoria = null
            this.categoria_eliminar = categoria
            this.id_categoria = categoria.id
            this.temporalCats = this.categorias.filter((item) => item.id !== categoria.id);
            this.$refs.modalEliminar.toggle()
        },
        async deleteCategoria(){
            try {
                const valid = await this.$refs.form2.validate()
                if(valid){
                    const {data} = await Categorias.deleteCategoria(this.id_categoria,this.categoria)
                    this.notificacion('Categoría eliminada exitosamente', data.message, 'success')
                    this.getDatos()
                    this.id_categoria = null
                    this.$refs.modalEliminar.toggle()
                    this.realodCategorias();
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        crearCategoria(cat){
            this.$refs.modalCrearCategoria.toggle(cat)
        },
        irDetalleSubcategorias(data){
            this.detalleCategoria = data;
            this.showSubcategorias = true;
        },
        accionesDesdeSubCategoria(accion){
            if(accion === 'eliminar'){
                this.eliminar(this.detalleCategoria);
            }
            if(accion === 'editar'){
                this.$refs.modalCrearCategoria.toggle(this.detalleCategoria);
            }
            if(accion === 'mostrarCategorias'){
                this.realodCategorias();
            }
        },
        realodCategorias(){
            this.showSubcategorias = false;
            this.detalleCategoria  = {};
        },
        actualizarDetalleCategoria(){
            let data = this.categorias.find(item => item.id === this.detalleCategoria.id);
            if(data){
                this.detalleCategoria = data;
            }
        },
        orderCategories(){
            this.$refs.modalOrderCategories.toggle();
        },

        async checkMoveCategory(data){
            const payload = {
                ganoPosicion: data.moved.element.id,
                nuevoIndex: data.moved.newIndex,
                viejoIndex: data.moved.oldIndex,
                tipo: 'LIST'
            };

            try {
                const { data } = await Categorias.orderCategories(payload);
                if (data.exito){
                    this.notificacion('Mensaje', 'Nuevo orden asignado', 'success');
                }
            } catch(e){
                this.error_catch(e);
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.name-banner{
    /* position: absolute; */
    color: #ffffff;
    /* left: 15px;
    top: 50%;
    transform: translate(0, -50%); */
}
.bg-gris0{
    background: #d0d0d0;
}
.nombre-categoria{
    height: 48px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    line-height: 19px;
}
</style>

<style>
.tooltip-size {
    max-width: 400px;
}
</style>
